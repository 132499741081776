const colors = {
  name: "colors",
  widget: "object",
  fields: [
    "base",
    "primary",
    "secondary",
    "accent",
    "accent-secondary",
    "lighter",
    "light",
    "dark",
    "error",
  ].map((color) => ({
    name: color,
    widget: "color",
  })),
};

export default {
  label: "Theme",
  name: "theme",
  file: "src/data/theme.json",
  format: "json",
  editor: {
    preview: false,
  },
  fields: [colors],
};
