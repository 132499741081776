import stepper from "./stepper";
import theme from "./theme";
import email from "./email";

const stepDefaults = (stepNumber) => ({
  label: `Step ${stepNumber > 4 ? stepNumber - 1 : stepNumber}`,
  name: `step_${stepNumber}`,
  file: `src/data/s${stepNumber}.json`,
  format: "json",
  editor: {
    preview: false,
  },
});

const image = {
  label: "Thumbnail",
  name: "image",
  widget: "object",
  fields: [
    {
      label: "Image",
      name: "src",
      default: "//via.placeholder.com/400x300",
      widget: "image",
    },
    { label: "Width", name: "width", default: 400, widget: "number" },
    {
      label: "Height",
      name: "height",
      default: 300,
      widget: "number",
    },
    {
      label: "Alt Text",
      name: "alt",
      widget: "string",
      required: false,
    },
  ],
};

const title = { label: "Title", name: "title", widget: "string" };

const question = {
  label: "Question",
  name: "question",
  widget: "string",
};

const imageSelector = [
  { label: "Label", name: "label", widget: "string" },
  { label: "Value", name: "value", widget: "hidden" },
  image,
];

const help_fields = [
  {
    name: "enabled",
    widget: "boolean",
    required: false,
    default: false,
  },
  {
    name: "label",
    widget: "string",
    hint: "icon text",
    required: false,
    default: "helpen",
  },
  {
    name: "title",
    widget: "string",
    required: false,
  },
  {
    name: "body",
    widget: "markdown",
    required: false,
  },
  {
    name: "images",
    widget: "list",
    required: false,
    fields: [
      {
        label: "Thumbnail",
        name: "src",
        widget: "image",
      },
      {
        name: "width",
        widget: "number",
      },
      {
        name: "height",
        widget: "number",
      },
      {
        label: "Alt text",
        name: "alt",
        widget: "string",
        required: false,
      },
    ],
  },
];

const help = {
  label: "Help",
  name: "help",
  widget: "object",
  collapsed: true,
  fields: help_fields,
};

const step1 = {
  ...stepDefaults(1),
  fields: [
    title,
    {
      label: "Options",
      name: "options",
      collapsed: false,
      widget: "object",
      fields: [
        {
          label: "Boy",
          name: "boy",
          collapsed: true,
          widget: "object",
          fields: imageSelector,
        },
        {
          label: "Girl",
          name: "girl",
          collapsed: true,
          widget: "object",
          fields: imageSelector,
        },
        {
          label: "Man",
          name: "man",
          collapsed: true,
          widget: "object",
          fields: imageSelector,
        },
        {
          label: "Vrouw",
          name: "woman",
          collapsed: true,
          widget: "object",
          fields: imageSelector,
        },
      ],
    },
    help,
  ],
};

const work_opt = [
  {
    label: "Label",
    name: "label",
    widget: "string",
  },
  question,
  image,
  {
    label: "Items",
    label_singular: "item",
    name: "collection",
    widget: "list",
    summary: "{{fields.item}}",
    field: { name: "item", widget: "string" },
  },
];

const step2_opt = [
  {
    label: "Label",
    name: "label",
    widget: "string",
  },
  question,
  image,
  {
    label: "Items",
    label_singular: "item",
    name: "collection",
    widget: "list",
    summary: "{{fields.label}}",
    fields: imageSelector,
  },
];

const step2 = {
  ...stepDefaults(2),
  fields: [
    title,
    {
      label: "Options",
      name: "options",
      collapsed: false,
      widget: "object",
      fields: [
        {
          label: "Daily",
          name: "daily",
          collapsed: true,
          widget: "object",
          fields: step2_opt,
        },
        {
          label: "Sport",
          name: "sport",
          collapsed: true,
          widget: "object",
          fields: step2_opt,
        },
        {
          label: "Work",
          name: "work",
          collapsed: true,
          widget: "object",
          fields: work_opt,
        },
        {
          label: "Footbed",
          name: "footBed",
          widget: "object",
          collapsed: true,
          fields: [
            question,
            {
              name: "options",
              widget: "object",
              fields: ["yes", "no"].map((name) => ({
                name,
                widget: "object",
                fields: imageSelector,
              })),
            },
          ],
        },
      ],
    },
    {
      name: "help",
      widget: "object",
      fields: ["substep 1", "substep 3"]
        .map((name) => ({
          name,
          widget: "object",
          collapsed: true,
          fields: help_fields,
        }))
        .concat([
          {
            name: "substep 2",
            widget: "object",
            fields: ["daily", "sport", "work"].map((name) => ({
              name,
              widget: "object",
              collapsed: true,
              fields: help_fields,
            })),
          },
        ]),
    },
  ],
};

const pain = [
  {
    name: "label",
    widget: "string",
  },
  {
    name: "description",
    widget: "text",
  },
  {
    name: "single",
    label: "No foot",
    widget: "boolean",
    default: false,
    required: false,
  },
  image,
];

const category = (name) => ({
  name: name,
  widget: "object",
  collapsed: true,
  summary: "{{fields.title}}",
  fields: [
    title,
    {
      name: "leftFoot",
      widget: "string",
      required: false,
      default: "Linkervoet",
    },
    {
      name: "rightFoot",
      widget: "string",
      required: false,
      default: "Rechtervoet",
    },
    {
      name: "collection",
      label: "Pains",
      label_singular: "Pain",
      widget: "list",
      fields: pain,
    },
  ],
});

const categories = [
  "vorvoet",
  "middenvoet",
  "achtervoet",
  "knie",
  "ankle",
  "whole",
].map((cat) => category(cat));

const selection = [
  {
    name: "values",
    widget: "list",
    summary: "{{fields.value}}",
    label_singular: "value",
    field: { name: "value", widget: "string" },
  },
];

const step3 = {
  ...stepDefaults(3),
  fields: [
    title,
    {
      label: "Options",
      name: "options",
      collapsed: false,
      widget: "object",
      fields: [
        {
          name: "havePain",
          label: "Have Pain?",
          widget: "object",
          collapsed: true,
          fields: ["yes", "no"].map((name) => ({
            name,
            widget: "object",
            fields: imageSelector,
          })),
        },
        {
          name: "pains",
          widget: "object",
          collapsed: true,
          fields: [
            title,
            {
              name: "categories",
              widget: "object",
              fields: categories,
            },
          ],
        },
        {
          name: "selection",
          widget: "object",
          collapsed: true,
          fields: [
            title,
            {
              name: "leftFoot",
              widget: "string",
            },
            {
              name: "rightFoot",
              widget: "string",
            },
            {
              name: "selection",
              label: "No foot",
              widget: "string",
            },
            {
              name: "frequency",
              widget: "object",
              collapsed: true,
              fields: selection,
            },
            {
              name: "strength",
              widget: "object",
              collapsed: true,
              fields: selection,
            },
            {
              name: "description",
              label: "Open question",
              widget: "object",
              collapsed: true,
              fields: [
                {
                  name: "enabled",
                  widget: "boolean",
                  default: false,
                  required: false,
                },
                {
                  name: "question",
                  widget: "string",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "help",
      widget: "object",
      fields: ["substep 1", "substep 2", "substep 3"].map((name) => ({
        name,
        widget: "object",
        collapsed: true,
        fields: help_fields,
      })),
    },
  ],
};

// const footType = [
//   {
//     label: "Label",
//     name: "label",
//     widget: "string",
//   },
//   image,
// ];

// const foot = (name) => ({
//   name,
//   label: `${name} foot`,
//   widget: "object",
//   collapsed: true,
//   summary: "{{fields.title}}",
//   fields: [
//     title,
//     {
//       name: "footTypes",
//       widget: "object",
//       fields: [
//         {
//           name: "flat",
//           widget: "object",
//           fields: footType,
//           collapsed: true,
//         },
//         {
//           name: "cavus",
//           widget: "object",
//           fields: footType,
//           collapsed: true,
//         },
//         {
//           name: "normal",
//           widget: "object",
//           fields: footType,
//           collapsed: true,
//         },
//       ],
//     },
//   ],
// });

// const step4 = {
//   ...stepDefaults(4),
//   fields: [
//     title,
//     {
//       label: "Options",
//       name: "options",
//       collapsed: false,
//       widget: "object",
//       fields: [foot("left"), foot("right")],
//     },
//     help,
//   ],
// };

const step5 = {
  ...stepDefaults(5),
  fields: [
    title,
    {
      label: "Options",
      name: "options",
      collapsed: false,
      widget: "object",
      fields: [
        {
          label: "Shoe Size",
          name: "shoeSize",
          collapsed: true,
          widget: "object",
          fields: [
            question,
            {
              name: "type",
              widget: "hidden",
            },
            {
              name: "min",
              widget: "number",
            },
            {
              name: "max",
              widget: "number",
            },
            {
              label: "Hint",
              name: "hint",
              widget: "string",
              required: false,
            },
          ],
        },
        {
          label: "Weight",
          name: "weight",
          collapsed: true,
          widget: "object",
          fields: [
            question,
            {
              label: "Options",
              name: "options",
              widget: "object",
              fields: ["low", "med", "high"].map((name) => ({
                name,
                widget: "object",
                collapsed: true,
                fields: imageSelector,
              })),
            },
          ],
        },
        {
          label: "Email",
          name: "email",
          collapsed: true,
          widget: "object",
          fields: [
            question,
            {
              name: "type",
              widget: "hidden",
            },
            {
              label: "Hint",
              name: "hint",
              widget: "string",
            },
          ],
        },
        {
          label: "Existing Customers",
          name: "isClient",
          collapsed: true,
          widget: "object",
          fields: [question],
        },
      ],
    },
    {
      name: "help",
      widget: "object",
      fields: ["substep 1", "substep 2", "substep 3"].map((name) => ({
        name,
        widget: "object",
        collapsed: true,
        fields: help_fields,
      })),
    },
  ],
};

const step6 = {
  ...stepDefaults(6),
  fields: [
    title,
    {
      name: "texts",
      widget: "list",
      label_singular: "text",
      required: false,
      field: {
        name: "text",
        widget: "string",
      },
    },
    {
      name: "alert",
      widget: "string",
    },
    {
      name: "confirm",
      widget: "string",
    },
    {
      name: "error",
      widget: "string",
    },
    {
      name: "print",
      widget: "string",
    },
    {
      label: "Options",
      name: "options",
      collapsed: false,
      widget: "object",
      fields: [
        {
          name: "summary",
          widget: "string",
        },
        {
          name: "cart",
          collapsed: true,
          widget: "object",
          fields: [
            {
              name: "label",
              widget: "string",
            },
            {
              name: "hint",
              widget: "string",
            },
            {
              name: "link",
              widget: "string",
            },
            {
              name: "qty",
              widget: "number",
              value_type: "int",
              required: false,
              default: 1,
              min: 1,
            },
          ],
        },
        {
          name: "products",
          collapsed: true,
          widget: "object",
          fields: [
            "daily_woman",
            "daily_man",
            "work",
            "boy",
            "girl",
            "run",
            "hike",
            "football",
            "hockey",
            "golf",
            "cycle",
            "winter",
            "indoor",
            "tennis",
          ].map((item) => ({
            name: item,
            widget: "number",
            value_type: "int",
          })),
        },
        {
          name: "labels",
          widget: "object",
          collapsed: true,
          fields: Object.entries({
            email: "Email",
            kid: "Kids",
            adult: "Volwassen",
            weight: "Lichaamsgewicht",
            footTypeLeft: "Voettype linkervoet",
            footTypeRight: "Voettype rechtervoet",
            removableFootBed: "Uitneembaar voetbed",
            shoeSize: "Schoenmaat",
            daily: "Dagelijks gebruik",
            sport: "Sport",
            work: "Voor veiligheidsschoenen",
            pain: "Klatchen",
            painDescription: "Pijnbeschrijving",
            leftFoot: "Linkervoet",
            rightFoot: "Retchervoet",
            isClient: "Bestaande klant",
          }).map((tupple) => {
            const [name, value] = tupple;
            return {
              name,
              widget: "string",
              default: value,
            };
          }),
        },
      ],
    },
  ],
};

const steps = {
  name: "steps",
  label: "Steps",
  files: [step1, step2, step3, step5, step6],
};

const settings = {
  name: "settings",
  label: "Settings",
  files: [stepper, theme, email],
};

const collections = [settings, steps];

const config = {
  backend: {
    name: "git-gateway",
    branch: "master",
  },
  local_backend: true,
  media_folder: "public/assets",
  public_folder: "/assets",
  locale: "nl",
  // site_url: "https://your-site.com"
  collections: collections,
};

export default config;
