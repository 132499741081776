export default {
  label: "Notifications",
  name: "email",
  file: "src/data/email.json",
  format: "json",
  editor: {
    preview: false,
  },
  fields: [
    {
      name: "theme",
      widget: "string",
    },
    {
      name: "company",
      widget: "object",
      fields: [
        {
          name: "name",
          widget: "string",
        },
        {
          name: "link",
          widget: "string",
        },
        {
          name: "logo",
          widget: "image",
        },
        {
          name: "logoHeight",
          widget: "number",
          value_type: "int",
          min: 50,
          max: 150,
        },
      ],
    },
    {
      name: "body",
      widget: "object",
      fields: [
        {
          name: "subject",
          widget: "string",
        },
        {
          name: "title",
          widget: "string",
        },
        {
          name: "intro",
          widget: "list",
          field: { name: "paragraph", widget: "string" },
        },
        {
          name: "outro",
          widget: "list",
          field: { name: "paragraph", widget: "string" },
        },
        {
          name: "action",
          widget: "object",
          fields: [
            { name: "instructions", widget: "string" },
            { name: "text", widget: "string" },
            { name: "color", widget: "color" },
            { name: "link", widget: "string" },
          ],
        },
      ],
    },
  ],
};
