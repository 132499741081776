const step = (n) => ({
  name: `step${n}`,
  label: `Step ${n > 4 ? n - 1 : n}`,
  widget: "object",
  fields: [
    {
      name: "title",
      widget: "string",
    },
    {
      name: "label",
      widget: "string",
    },
  ],
});

export default {
  label: "Base",
  name: "stepper",
  file: "src/data/stepper.json",
  format: "json",
  editor: {
    preview: false,
  },
  fields: [
    { name: "title", widget: "string" },
    { name: "prev", widget: "string" },
    { name: "next", widget: "string" },
  ].concat([1, 2, 3, 5, 6].map(step)),
};
